import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { StyledToasterButton } from '../common';

const CustomToaster = ({htmlStyling}) => {
  return (
    <div>
      <Toaster
        reverseOrder={false}
        position='top-center'
        toastOptions={{
            style: {
              backgroundColor: `#${htmlStyling.Color2}`,
              color: '#000000'
            },
            iconTheme: {
              primary: `#${htmlStyling.Color1}`,
              secondary: `#${htmlStyling.Color4}`,
            },
          
          }
        }
      >
        {t => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && <StyledToasterButton onClick={() => toast.dismiss(t.id)}>Dismiss</StyledToasterButton>}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
};

export default CustomToaster;
