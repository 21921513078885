import { Box, FormControl } from '@mui/material';
import { isValid } from 'iban';
import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledErrorText, StyledMainInfoText, StyledTypography } from '../../../../components/common';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';
import { StyledTextField, StyledWrapper } from './StyledSingleTextField';

const SingleTextField = ({ item, handleQuestionCount, apiPostError }) => {
  const prevValueRef = useRef('');
  const { control, setValue, getValues, errors } = useFormContext();

  const patternRegex = item?.RegExMask ? new RegExp(item?.RegExMask.slice(1, -1)) : null;
  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();
    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue(surveyQuestionIdentifier?.toString(), savedValue.trim());
      prevValueRef.current = savedValue;
    }
  }, [control, item]);

  const handleInputChange = event => {
    const currentValue = event.target.value;
    const prevValue = prevValueRef.current;

    if (currentValue && !prevValue) {
      handleQuestionCount(1);
    } else if (!currentValue && prevValue) {
      handleQuestionCount(-1);
    }

    prevValueRef.current = currentValue;
  };

  return (
    <Box>
      <StyledWrapper>
        <StyledMainInfoText>
          <StyledTypography variant='h2'>
            {item.Question} {item?.Mandatory && <RequiredStar />}
          </StyledTypography>
          <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
        </StyledMainInfoText>
        <Controller
          name={(item?.SurveyQuestionIdentifier).toString()}
          control={control}
          rules={{
            required: item?.Mandatory,
            pattern: patternRegex,
            maxLength: item?.MaximumLength,
            minLength: item?.MinimumLength,
            validate: value => {
              if (item?.IsIBAN && value) {
                return isValid(value);
              }
              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <StyledTextField
                id='outlined-basic'
                variant='outlined'
                disabled={item?.ShowReadOnly}
                {...field}
                inputProps={{
                  style: {
                    textTransform: item?.Capital ? 'uppercase' : 'none',
                  },
                }}
                onChange={e => {
                  field.onChange(e);
                  handleInputChange(e);
                }}
              />
            </FormControl>
          )}
        />
      </StyledWrapper>
      <StyledErrorText paddingtop='10px'>
        {errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'required' ? (
          <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'pattern' ? (
          <div className='error-text'>{item?.InvalidInputMessage || 'Invalid Format'}</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'maxLength' ? (
          <div className='error-text'>Text is too long.</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'minLength' ? (
          <div className='error-text'>Text is too short.</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'validate' ? (
          <div className='error-text'>{item?.InvalidInputMessage || 'Invalid Format'}</div>
        ) : null}
        {apiPostError && <div className='error-text'>{apiPostError}</div>}
      </StyledErrorText>
    </Box>
  );
};

export default SingleTextField;
