import { FormControl, MenuItem, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledErrorText, StyledMainInfoText, StyledMainInfoWrapper } from '../../../../components/common';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';
import { StyledMainSelectContent, StyledSelect } from './StyledDropdowmMenu';

const DropdownMenu = ({ item, multiple, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();
  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();
    const savedValue =
      surveyQuestionIdentifier &&
      values.hasOwnProperty(surveyQuestionIdentifier) &&
      values[surveyQuestionIdentifier] !== undefined &&
      values[surveyQuestionIdentifier].length
        ? values[surveyQuestionIdentifier]
        : item?.Value;
    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleChange = (value, selectValue, onChange) => {
    if (multiple) {
      const localCheckBoxes = [...value];
      const newValue =
        localCheckBoxes && localCheckBoxes.includes(selectValue)
          ? localCheckBoxes.filter(item => item !== selectValue)
          : [...(localCheckBoxes || []), selectValue];
      setValue((item?.SurveyQuestionIdentifier).toString(), newValue);
      onChange(newValue);
      if (!item?.Value && value) {
        handleQuestionCount(1);
      } else if (!getValues(item?.SurveyQuestionIdentifier).length) {
        handleQuestionCount(-1);
      }
    } else {
      setValue((item?.SurveyQuestionIdentifier).toString(), selectValue);
      onChange(selectValue);
      if (!item?.Value && selectValue) {
        handleQuestionCount(1);
      } else if (!selectValue) {
        handleQuestionCount(-1);
      }
    }
  };

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <Typography variant='h2'>{item.Question} {item?.Mandatory && <RequiredStar />}</Typography>
        <Typography variant='h3'>{item.QuestionExplanation}</Typography>
      </StyledMainInfoText>
      <StyledMainSelectContent>
        <Controller
          name={(item?.SurveyQuestionIdentifier).toString()}
          control={control}
          defaultValue={multiple ? [] : ''}
          rules={{ required: item?.Mandatory }}
          render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
            return (
              <FormControl>
                <StyledSelect
                  labelId='demo-multiple-name-label'
                  id='demo-multiple-name'
                  multiple={multiple}
                  value={value}
                  onChange={event => {
                    handleChange(value, event.target.value, onChange);
                  }}
                  error={!!error}
                  {...fieldProps}
                  disabled={item?.ShowReadOnly}
                >
                  {item?.ItemList?.map(({ ItemNumber, ItemAnswerValue, ItemLabel }) => (
                    <MenuItem key={ItemNumber} value={ItemAnswerValue}>
                      {ItemLabel}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            );
          }}
        />
        <StyledErrorText paddingtop='20px'>
          {errors[(item?.SurveyQuestionIdentifier).toString()] && <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>}
          {apiPostError && <div className='error-text'>{apiPostError}</div>}
        </StyledErrorText>
      </StyledMainSelectContent>
    </StyledMainInfoWrapper>
  );
};

export default DropdownMenu;
