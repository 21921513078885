import { Box, Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import styled from 'styled-components';

export const StyledButton = styled(LoadingButton)`
  && {
    width: ${props => props.width || '312px'};
    height: 56px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 625;
    text-transform: none;
  }
`;

export const StyledPrevButton = styled(Button)`
  && {
    width: ${props => props.width || '312px'};
    height: 56px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 625;
    text-transform: none;
    border: 2px solid;
    &:hover{
      border: 2px solid;
    }
  
  }
`;

export const StyledButtonsWrapper = styled(Box)`
  display: flex;
  gap: 30px;
  padding-top: ${props => props.paddingtop || '0px'};
  @media (max-width: 570px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const StyledMainInfoWrapper = styled(Box)``;

export const StyledMainWrapper = styled(Box)`
  max-width: ${props => props.maxwidth || '100%'};
  padding-top: ${props => props.paddingtop || '0px'};
  padding-bottom: ${props => props.paddingbottom || '0px'};
`;

export const StyledMainInfoText = styled(Box)`
  text-align: start;
  & h1,
  & h2 {
    font-weight: 550;
    font-size: ${props => props.h1fontsize || '26px'};
    margin: 0;
    font-family: 'Campton Bold', sans-serif;
    @media (max-width: 570px) {
      font-size: 14.5px;
    }
  }

  & h3 {
    font-size: ${props => props.pfontsize || '18px'};
    margin: 1em 0;
    font-family: 'Avenir Next', sans-serif;
    @media (max-width: 570px) {
      font-size: 13px;
    }
  }
`;

export const StyledCheckBoxWrapper = styled(Box)`
  & ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    font-family: 'Avenir Next Bold', sans-serif;
  }
`;

export const StyledCheckBoxesWrapper = styled(Box)`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  & ul {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 0;
    font-family: 'Avenir Next Bold', sans-serif;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledSingleCheckBoxWrapper = styled(Box)`
  display: flex;
  align-items: center;
  & p {
    font-size: 13px;
  }
  & ul {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 0;
    font-family: 'Avenir Next Bold', sans-serif;
  }
`;

export const StyledOptionBlock = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 20px;
  font-family: 'Avenir Next Bold', sans-serif;
  width: 35%;
  @media (max-width: 570px) {
    width: 100%;
  }
`;

export const StyledMainInfoContent = styled(Box)`
  display: flex;
  justify-content: start;
  padding-top: ${props => props.paddingtop || '0px'};
  @media (max-width: 570px) {
    justify-content: center;
  }
`;

export const StyledErrorText = styled(Box)`
  text-align: start;
  padding-top: ${props => props.paddingtop || '0px'};
`;

export const StyledLabel = styled('span')`
  font-family: 'Avenir Next Bold', sans-serif;
`;

export const StyledSurveyGrid = styled(Grid)`
  && {
    margin: 0px 0 35px 0;
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
    }
  }
`;

export const StyledTypographyGrid = styled(Grid)`
  display: flex;
  align-items: center;
  white-space: pre-line;
`;

export const StyledTypography = styled(Typography)`
  white-space: pre-line;
`;

export const StyledToasterButton = styled('button')`
&& {
  padding: 0.25rem 0.5rem;
  background-color: #ececec;
  border-color: #d3d3d3;
  border-radius: 6px;
}
`
