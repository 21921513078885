import React, { useEffect } from 'react';
import { StyledErrorText, StyledMainInfoText, StyledMainInfoWrapper, StyledTypography } from '../../../../components/common';
import { StyledImagesList, StyledLabelWrapper, StyledMainImageContent, StyledMenuImage, StyledMenuImageWrapper } from './StyledImageMenu';
import { Controller, useFormContext } from 'react-hook-form';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';
import RadioCheckbox from './components/RadioCheckbox';

const ImageMenu = ({ item, multiple, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();
  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier &&
      values.hasOwnProperty(surveyQuestionIdentifier) &&
      values[surveyQuestionIdentifier] !== undefined &&
      values[surveyQuestionIdentifier].length
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleCheckBox = (upperLabel, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), upperLabel);
    onChange(upperLabel);
    if (!item?.Value && upperLabel) {
      handleQuestionCount(1);
    } else if (!upperLabel) {
      handleQuestionCount(-1);
    }
  };

  const handleCheckBoxMultiple = (value, upperLabel, onChange) => {
    if (!item?.ShowReadOnly) {
      const localCheckBoxes = [...value];
      const newValue =
        localCheckBoxes && localCheckBoxes.includes(upperLabel)
          ? localCheckBoxes.filter(item => item !== upperLabel)
          : [...(localCheckBoxes || []), upperLabel];
      setValue((item?.SurveyQuestionIdentifier).toString(), newValue);
      onChange(newValue);
      if (!item?.Value && value) {
        handleQuestionCount(1);
      } else if (!getValues(item?.SurveyQuestionIdentifier).length) {
        handleQuestionCount(-1);
      }
    }
  };

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>
          {item.Question} {item?.Mandatory && <RequiredStar />}
        </StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <StyledMainImageContent paddingtop='10px'>
        <StyledImagesList>
          {item?.ItemList.map(image => (
            <Controller
              name={(item?.SurveyQuestionIdentifier).toString()}
              control={control}
              key={image.ItemLabel}
              defaultValue={multiple ? [] : ''}
              rules={{ required: item?.Mandatory }}
              render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
                return (
                  <>
                    <StyledMenuImageWrapper onClick={() => handleCheckBoxMultiple(value, image.ItemAnswerValue, onChange)}>
                      <StyledMenuImage src={image.ItemImageURL} alt={image.ItemLabel} />
                      <RadioCheckbox
                        multiple={multiple}
                        fieldProps={fieldProps}
                        image={image}
                        value={value}
                        handleCheckBoxMultiple={handleCheckBoxMultiple}
                        handleCheckBox={handleCheckBox}
                        onChange={onChange}
                        item={item}
                        position='absolute'
                      />
                      {item?.LabelVisible && (
                        <StyledLabelWrapper>
                          <RadioCheckbox
                            multiple={multiple}
                            fieldProps={fieldProps}
                            image={image}
                            value={value}
                            handleCheckBoxMultiple={handleCheckBoxMultiple}
                            handleCheckBox={handleCheckBox}
                            onChange={onChange}
                            item={item}
                          />
                          <p>{image?.ItemLabel}</p>
                        </StyledLabelWrapper>
                      )}
                    </StyledMenuImageWrapper>
                  </>
                );
              }}
            />
          ))}
        </StyledImagesList>
        <StyledErrorText paddingtop='20px'>
          {errors[(item?.SurveyQuestionIdentifier).toString()] && (
            <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
          )}
             {apiPostError && <div className='error-text'>{apiPostError}</div>}
        </StyledErrorText>
      </StyledMainImageContent>
    </StyledMainInfoWrapper>
  );
};

export default ImageMenu;
