/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import Loader from './pages/Loader/Loader';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import HomePage from './pages/Homepage/HomePage';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import ThanksPage from './pages/ThanksPage/ThanksPage';
import { useToken } from './contexts/TokenContext';
import { createOwnTheme } from './utils/theme';
import CustomToaster from './pages/components/CustomToaster/CustomToaster';
import toast, { useToasterStore } from 'react-hot-toast';
import { handleRequest } from './utils/handles';
import axios from 'axios';
import sha256 from 'crypto-js/sha256';

function App() {
  const [data, setData] = useState({});
  const [status, setStatus] = useState('');
  const [htmlStyling, setHtmlStyling] = useState({});
  const [searchParams] = useSearchParams();
  const SurveyID = searchParams.get('SurveyID');
  const LanguageCode = searchParams.get('LanguageCode');
  const ColorPaletteID = searchParams.get('ColorPaletteID');
  const IgnoreSurveyScreenNumbers = searchParams.get('IgnoreSurveyScreenNumbers');
  const StartWithIntroScreen = searchParams.get('StartWithIntroScreen');
  const IgnorePreviousAnswers = searchParams.get('IgnorePreviousAnswers');
  const SurveyType = searchParams.get('SurveyType');
  const ScreenNumber = Number(searchParams.get('ScreenNumber'));
  const [screen, setScreen] = useState(0);
  const { setToken } = useToken();
  const theme = createOwnTheme(htmlStyling);
  const [endData, setEndData] = useState({});

  useEffect(() => {
    handleRequest(
      null,
      SurveyID,
      LanguageCode,
      ColorPaletteID,
      IgnoreSurveyScreenNumbers,
      StartWithIntroScreen,
      IgnorePreviousAnswers,
      SurveyType,
      setStatus,
      setData,
      setScreen,
      setHtmlStyling,
      setToken,
      ScreenNumber,
      window.innerWidth <= 768,
    );
  }, [SurveyID, LanguageCode, SurveyType, ColorPaletteID, IgnoreSurveyScreenNumbers, StartWithIntroScreen, IgnorePreviousAnswers, setToken]);

  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 1;

  useEffect(() => {
    toasts
      .filter(t => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach(t => toast.dismiss(t.id));
  }, [toasts]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const clientIdResponse = await axios.post(
          'https://api.sl2.nl/api/relatiebeheer/LoginService/GetToken',
          { ClientID: process.env.REACT_APP_CLIENT_ID },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const sha256Value = sha256(process.env.REACT_APP_SECURITY_API_KEY + process.env.REACT_APP_SALT + clientIdResponse.data.value).toString();
        const tokenResponse = await axios.post(
          'https://api.sl2.nl/api/relatiebeheer/LoginService/LoginToken',
          { Token: clientIdResponse.data.value, Hash: sha256Value },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        setToken(tokenResponse.data.value);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };
    const interval = setInterval(fetchToken, 4 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (status === 400) return <ErrorPage text={'Incorrect Survey ID'} />;
  if (status !== 200) return <Loader />;

  const handleLanguage = value => {
    handleRequest(
      value,
      SurveyID,
      LanguageCode,
      ColorPaletteID,
      IgnoreSurveyScreenNumbers,
      StartWithIntroScreen,
      IgnorePreviousAnswers,
      SurveyType,
      setStatus,
      setData,
      setScreen,
      setHtmlStyling,
      setToken,
      ScreenNumber,
    );
  };

  const handleScreen = (operation, count) => {
    toast.dismiss();
    operation === 'plus' ? setScreen(prev => prev + Number(count)) : setScreen(prev => prev - Number(count));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        {screen === 0 && <HomePage data={data} handleClick={handleScreen} screenNumber={ScreenNumber} />}
        {screen !== 0 && screen <= data?.TotalAmountScreens && (
          <SurveyPage
            data={data}
            languages={data?.Languages}
            handleLanguage={handleLanguage}
            screen={screen}
            handleScreen={handleScreen}
            handleEndData={setEndData}
          />
        )}
        {screen > data?.TotalAmountScreens && <ThanksPage data={data} endData={endData} />}
      </div>
      <CustomToaster htmlStyling={htmlStyling} />
    </ThemeProvider>
  );
}

export default App;
