/* eslint-disable array-callback-return */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Wrapper from '../../components/Wrapper/Wrapper';
import { StyledFooterText, StyledForm, StyledHeaderText, StyledWrapper } from './StyledScreen';
import SubmitButtons from './components/SubmitButtons/SubmitButtons';
import { StyledLogo } from '../../components/Header/StyledHeader';
import { StyledSurveyGrid, StyledTypographyGrid } from '../../components/common';
import { useToken } from '../../../contexts/TokenContext';
import { handleErrors } from '../../../utils/errors';
import { areAllQuestionsHidden, handleQuestionAnswered, handleQuestionsCount, renderQuestions } from '../../../utils/questions';
import { handleFormSubmit } from '../../../utils/handles';

const Screen = ({ screens, styling, surveyData, languages, handleLanguage, questions, screen, handleScreen, handleEndData }) => {
  const { token, setToken } = useToken();
  const currentScreenWrapperInfo = screens[screen - 1];
  const currentScreenQuestions = screens[screen - 1]?.SurveyQuestionList;
  const headerText = currentScreenWrapperInfo?.HeaderText.trim('');
  const [questionsCount, setQuestionsCount] = useState(questions);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [apiPostErrors, setApiPostErrors] = useState([]);
  const handleQuestionCount = val => {
    setQuestionsAnswered(prev => prev + val);
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const formValues = watch();
  const [requiredErrors, setRequiredErrors] = useState(JSON.stringify(errors));
  useEffect(() => {
    handleQuestionsCount(setQuestionsCount, screens, getValues);
    handleQuestionAnswered(setQuestionsAnswered, screens, getValues, screen, handleQuestionCount);
    setRequiredErrors(JSON.stringify(errors));
  }, [formValues]);

  useEffect(() => {
    handleErrors(JSON.parse(requiredErrors),currentScreenWrapperInfo);
  }, [requiredErrors]);

  return (
    <Wrapper
      headerText={currentScreenWrapperInfo?.ScreenTitle}
      screens={screens}
      currentScreen={screen}
      imageUrl={currentScreenWrapperInfo?.ImageURL}
      languages={languages}
      handleLanguage={handleLanguage}
      questions={questionsCount}
      questionCount={questionsAnswered}
      showProgressBar={surveyData?.SurveyStyling?.ShowProgressBar}
    >
      <StyledWrapper paddingTop={'25px'}>
        <StyledHeaderText container>
          <StyledTypographyGrid item xs={12} md={headerText ? 9 : 12} sm={headerText ? 9 : 12}>
            <Typography variant='h3'>{currentScreenWrapperInfo?.HeaderText}</Typography>
          </StyledTypographyGrid>
          <Box>
            <StyledLogo src={currentScreenWrapperInfo?.ImageURL} alt='Image Logo' />
          </Box>
        </StyledHeaderText>
        <StyledSurveyGrid container>
          <Grid item md={!headerText ? 9 : 12} sm={!headerText ? 9 : 12} sx={{ width: '100%' }}>
            <StyledForm
              component='form'
              noValidate
              onSubmit={handleSubmit(data =>
                handleFormSubmit(
                  data,
                  false,
                  areAllQuestionsHidden,
                  handleScreen,
                  surveyData,
                  getValues,
                  screen,
                  token,
                  setApiPostErrors,
                  setToken,
                  currentScreenQuestions,
                  screens,
                  handleQuestionCount,
                  handleEndData
                ),
              )}
            >
              <FormProvider control={control} errors={errors} setValue={setValue} getValues={getValues}>
                {renderQuestions(apiPostErrors, currentScreenQuestions, handleQuestionCount, questionsAnswered, screens, getValues)}
              </FormProvider>
              <SubmitButtons
                styling={styling}
                screens={screens}
                handlePrevScreen={handleFormSubmit}
                areAllQuestionsHidden={areAllQuestionsHidden}
                handleScreen={handleScreen}
                surveyData={surveyData}
                getValues={getValues}
                token={token}
                setApiPostErrors={setApiPostErrors}
                setToken={setToken}
                currentScreen={screen}
                currentScreenQuestions={currentScreenQuestions}
                handleQuestionCount={handleQuestionCount}
                handleEndData={handleEndData}
              />
            </StyledForm>
          </Grid>
        </StyledSurveyGrid>
        <StyledFooterText sx={{ fontSize: '24px', whiteSpace: 'pre-line' }}>
          <Typography variant='h3'>{currentScreenWrapperInfo?.FooterText}</Typography>
        </StyledFooterText>
      </StyledWrapper>
    </Wrapper>
  );
};

export default Screen;
