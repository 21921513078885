import React, { useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, Checkbox, Radio } from '@mui/material';
import {
  StyledErrorText,
  StyledMainInfoText,
  StyledMainInfoWrapper,
  StyledOptionBlock,
  StyledTypography,
} from '../../../../components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledCheckBoxesWrapper, StyledFormControlLabel } from './StyledCategoricalQuestion';
import ImageMenu from '../ImageMenu/ImageMenu';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';

const CategoricalQuestion = ({ item, multiple, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();
  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();
    const savedValue =
      surveyQuestionIdentifier &&
      values.hasOwnProperty(surveyQuestionIdentifier) &&
      values[surveyQuestionIdentifier] !== undefined &&
      values[surveyQuestionIdentifier].length
        ? values[surveyQuestionIdentifier]
        : item?.Value;
    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleCheckBox = (upperLabel, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), upperLabel);
    onChange(upperLabel);
    if (!item?.Value && upperLabel) {
      handleQuestionCount(1);
    } else if (!upperLabel) {
      handleQuestionCount(-1);
    }
  };

  const handleCheckBoxMultiple = (value, upperLabel, onChange) => {
    const localCheckBoxes = [...value];
    const newValue =
      localCheckBoxes && localCheckBoxes.includes(upperLabel)
        ? localCheckBoxes.filter(item => item !== upperLabel)
        : [...(localCheckBoxes || []), upperLabel];
    setValue((item?.SurveyQuestionIdentifier).toString(), newValue);
    onChange(newValue);
    if (!item?.Value && value) {
      handleQuestionCount(1);
    } else if (!getValues(item?.SurveyQuestionIdentifier).length) {
      handleQuestionCount(-1);
    }
  };

  if (item.DisplayType === 'dtmoImageSet') {
    return <ImageMenu apiPostError={apiPostError} item={item} multiple={multiple} handleQuestionCount={handleQuestionCount} />;
  } else if (item.DisplayType === 'dtsoDropDown') {
    return <DropdownMenu apiPostError={apiPostError} item={item} multiple={multiple} handleQuestionCount={handleQuestionCount} />;
  }

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>{item.Question} {item?.Mandatory && <RequiredStar />}</StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <Box>
        <StyledCheckBoxesWrapper>
         
            {item?.ItemList?.map(rating => (
              <StyledOptionBlock key={rating.ItemNumber}>
                <Controller
                  name={(item?.SurveyQuestionIdentifier).toString()}
                  control={control}
                  defaultValue={multiple ? [] : ''}
                  rules={{ required: item?.Mandatory }}
                  render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
                 
                    return (
                      <>
                        {multiple ? (
                          <StyledFormControlLabel
                            value={rating.ItemAnswerValue}
                            control={
                              <Checkbox
                                {...fieldProps}
                                value={rating.ItemAnswerValue}
                                color='common'
                                disabled={item?.ShowReadOnly}
                                checked={value && value.includes(rating.ItemAnswerValue)}
                                onChange={() => handleCheckBoxMultiple(value, rating.ItemAnswerValue, onChange)}
                              />
                            }
                            label={rating.ItemLabel}
                          />
                        ) : (
                          <StyledFormControlLabel
                            value={rating.ItemAnswerValue}
                            control={
                              <Radio
                                {...fieldProps}
                                disabled={item?.ShowReadOnly}
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={value === rating.ItemAnswerValue}
                                onChange={() => handleCheckBox(rating.ItemAnswerValue, onChange)}
                              />
                            }
                            label={rating.ItemLabel}
                          />
                        )}
                      </>
                    );
                  }}
                />
              </StyledOptionBlock>
            ))}
       
        </StyledCheckBoxesWrapper>
        <StyledErrorText paddingtop='20px'>
          {errors[(item?.SurveyQuestionIdentifier).toString()] && (
            <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
          )}
        </StyledErrorText>
      </Box>
    </StyledMainInfoWrapper>
  );
};

export default CategoricalQuestion;
