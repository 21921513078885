import { Box, Slider } from '@mui/material';
import styled from 'styled-components';

export const StyledSliderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 648px;
  gap: 24px;
`;

export const StyledSliderList = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Avenir Next Bold';
  @media (max-width: 570px) {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }
`;

export const StyledMuiSlider = styled(Slider)`
  && {
    max-width: 424px;
    & span.MuiSlider-thumb {
      color: #ffffff;
      width: 28px;
      height: 28px;
    }
  }
`;

export const StyledSliderMainWrapper = styled(Box)`
display: flex;
width: 100%;
flex-wrap: wrap;
max-width: 900px;
justify-content: space-between;
align-items: center;
h2 {
  margin-bottom: 10px;
}
`
