import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

export const StyledWrapper = styled(Box)`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: 570px) {
    padding: 50px 0 80px 0;
  }
`;

export const StyledHeaderText = styled(Grid)`
  && {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: start;
    && h3 {
      font-size: 18px;
      font-family: 'Campton', sans-serif;
      text-align: start;
      @media (max-width: 570px) {
        font-size: 14.5px;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      gap: 15px;
    }
  }
`;
export const StyledFooterText = styled(Box)`
  && h3 {
    font-size: 18px;
    font-family: 'Campton', sans-serif;
    text-align: start;
    @media (max-width: 570px) {
      font-size: 14.5px;
    }
  }
`;

export const StyledForm = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 570px) {
    gap: 20px;
  }
`;
