import toast from 'react-hot-toast';

export const handleErrors = (errors, data) => {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const objectData = errors[key];
      if (objectData.type && objectData.type === 'required') {
        toast.error(data?.MissingAnswerMessage);
      }
    }
  }
};
