import { Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { StyledErrorPageWrapper } from './StyledErrorPage';

const ErrorPage = ({ text }) => {
  return (
    <StyledErrorPageWrapper>
      <Helmet>
        <title>Error Page - Loyalty</title>
      </Helmet>
      <Typography variant='h1' color='#3A5278'>
        404
      </Typography>
      <Typography variant='h6' color='#3A5278'>
        {text}
      </Typography>
     
    </StyledErrorPageWrapper>
  );
};

export default ErrorPage;
