import { useTheme } from '@mui/material';
import React from 'react'

const RequiredStar = () => {
    const theme = useTheme();
  return (
    <span className='error-text' style={{ color: theme.palette.primary.main }}>*</span>
  )
}

export default RequiredStar