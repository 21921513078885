import { Box, Select } from '@mui/material';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  width: 250px;
`;

export const StyledMainSelectContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.paddingtop || '0px'};
`;
