import { Container } from '@mui/material';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  && {
    min-height: 100%;
    position: relative;
    padding: 0px ${props => props.padding || '24px'};
  }
`;
