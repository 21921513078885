import { Box, TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledWrapper = styled(Box)`
  display: flex;
  justify-content: start;
  flex-direction column;
`;

export const StyledTextField = styled(TextField)``;
