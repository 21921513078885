import { FormControl } from '@mui/material';
import { isValid } from 'iban';
import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledErrorText, StyledMainInfoText, StyledTypography } from '../../../../components/common';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';
import { StyledTextField, StyledWrapper } from './StyledMultipleTextField';

const MultipleTextField = ({ item, handleQuestionCount, questionCount, apiPostError }) => {
  const prevValueRef = useRef('');
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();
    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue(surveyQuestionIdentifier?.toString(), savedValue.trim());
      prevValueRef.current = savedValue;
    }
  }, [control, item]);

  const handleChange = e => {
    const currentValue = e.target.value;
    const prevValue = prevValueRef.current;

    if (currentValue && !prevValue) {
      handleQuestionCount(1);
    } else if (!currentValue && prevValue) {
      handleQuestionCount(-1);
    }

    prevValueRef.current = currentValue;
  };

  return (
    <StyledWrapper sx={{ width: 'fit-content' }}>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>{item.Question} {item?.Mandatory && <RequiredStar />}</StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <Controller
        name={(item?.SurveyQuestionIdentifier).toString()}
        control={control}
        rules={{
          required: item?.Mandatory,
          pattern: item?.RegExMask ? new RegExp(item?.RegExMask) : null,
          maxLength: item?.MaximumLength,
          minLength: item?.MinimumLength,
          validate: value => {
            if (item?.IsIBAN && value) {
              return isValid(value);
            }
            return true;
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <StyledTextField
              id='outlined-multiline-static'
              {...field}
              inputProps={{
                style: {
                  textTransform: item?.Capital ? 'uppercase' : 'none',
                },
              }}
              disabled={item?.ShowReadOnly}
              multiline
              rows={4}
              defaultValue=''
              onChange={e => {
                field.onChange(e);
                handleChange(e);
              }}
            />
          </FormControl>
        )}
      />
      <StyledErrorText paddingtop='10px'>
        {errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'required' ? (
          <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'pattern' ? (
          <div className='error-text'>{item?.InvalidInputMessage || 'Invalid Format'}</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'maxLength' ? (
          <div className='error-text'>Text is too long.</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'minLength' ? (
          <div className='error-text'>Text is too short.</div>
        ) : errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'validate' ? (
          <div className='error-text'>{item?.InvalidInputMessage || 'Invalid Format'}</div>
        ) : null}
        {apiPostError && <div className='error-text'>{apiPostError}</div>}
      </StyledErrorText>
    </StyledWrapper>
  );
};

export default MultipleTextField;
