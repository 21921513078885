import React from 'react';
import { StyledMainInfoText, StyledMainWrapper, StyledTypography } from '../common';

const ThanksBanner = ({ title, description }) => {
  return (
    <StyledMainWrapper paddingbottom='100px' paddingtop='50px'>
      <StyledMainInfoText h1fontsize='26px' pfontsize='18px'>
        <StyledTypography variant='h1'>{title}</StyledTypography>
        <StyledTypography variant='h3'>{description}</StyledTypography>
      </StyledMainInfoText>
    </StyledMainWrapper>
  );
};

export default ThanksBanner;
