import React from 'react';
import { StyledButton, StyledButtonsWrapper, StyledPrevButton } from '../../../../components/common';

const SubmitButtons = ({
  styling,
  currentScreen,
  screens,
  handlePrevScreen,
  areAllQuestionsHidden,
  handleScreen,
  surveyData,
  getValues,
  token,
  setApiPostErrors,
  setToken,
  currentScreenQuestions,
  handleQuestionCount,
  handleEndData
}) => {
  return (
    <StyledButtonsWrapper paddingtop='30px'>
      {currentScreen === 1 ? (
        <StyledButton variant='contained' type='submit'>
          {styling?.ButtonNextScreen}
        </StyledButton>
      ) : currentScreen === screens.length ? (
        <React.Fragment>
          {styling?.NavigationIsAllowed && (
            <StyledPrevButton
              variant='outlined'
              border='common.main'
              onClick={() =>
                handlePrevScreen(
                  null,
                  true,
                  areAllQuestionsHidden,
                  handleScreen,
                  surveyData,
                  getValues,
                  currentScreen,
                  token,
                  setApiPostErrors,
                  setToken,
                  currentScreenQuestions,
                  screens,
                  handleQuestionCount,
                  handleEndData
                )
              }
            >
              {styling?.ButtonPreviousScreen}
            </StyledPrevButton>
          )}
          <StyledButton variant='contained' type='submit'>
            {styling?.ButtonEndSurveyCaption}
          </StyledButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {styling?.NavigationIsAllowed && (
            <StyledPrevButton
              variant='outlined'
              border='common.main'
              onClick={() =>
                handlePrevScreen(
                  null,
                  true,
                  areAllQuestionsHidden,
                  handleScreen,
                  surveyData,
                  getValues,
                  currentScreen,
                  token,
                  setApiPostErrors,
                  setToken,
                  currentScreenQuestions,
                  screens,
                  handleQuestionCount,
                  handleEndData
                )
              }
            >
              {styling?.ButtonPreviousScreen}
            </StyledPrevButton>
          )}
          <StyledButton variant='contained' type='submit'>
            {styling?.ButtonNextScreen}
          </StyledButton>
        </React.Fragment>
      )}
    </StyledButtonsWrapper>
  );
};

export default SubmitButtons;
