import { Box, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    & span {
      font-family: 'Avenir Next Bold';
      padding: 0;
      text-align: start;
      @media (max-width: 600px) {
        font-size: 14.5px;
      }
    }
    display: flex;
    gap: 10px;
    align-items: start;
  }
`;

export const StyledCheckBoxesWrapper = styled(Box)`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  font-family: 'Avenir Next Bold', sans-serif;
  gap: 30px;
  align-items: flex-start;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;
