import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledFooter = styled(Box)`
  height: 64px;
  width: 100%;
  color: #5c5e5f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-family: 'Avenir Next Bold', sans-serif;
  z-index: 1;
  max-width: 1488px;
  @media (max-width: 570px) {
    height: 30px;
  }
`;

export const StyledFooterWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
`;

export const StyledTypography = styled(Typography)`
  && {
    font-size: ${props => props.pfontsize || '17px'};
    margin: 1em 0;
    font-family: 'Avenir Next', sans-serif;
  }
`;
