import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './assets/fonts/Avenir/AvenirNextCyr-Regular.ttf';
import './assets/fonts/Campton/Campton-Black.ttf';
import { TokenProvider } from './contexts/TokenContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <TokenProvider>
      <App />
    </TokenProvider>
  </BrowserRouter>,
);
