import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledImage = styled('img')``;

export const StyledDragOption = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 424px;
`;

export const StyledDragTextWrapper = styled(Box)`
  width: 388px;
  padding: 16px;
  height: 48px;
  border-radius: 8px;
  font-weight: 550;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  font-family: 'Avenir Next Bold', sans-serif;
  & p {
    color: #ffffff;
  }
  @media (max-width: 570px) {
    width: 294px;
  }
`;

export const StyledDragList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDragNumber = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #5c5e5f;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 12px;
`;
