/* eslint-disable array-callback-return */
import CategoricalQuestion from '../pages/SurveyPage/Screen/components/CategoricalQuestion/CategoricalQuestion';
import DatePickerQuestion from '../pages/SurveyPage/Screen/components/DatePicker/DatePicker';
import DichotomousQuestion from '../pages/SurveyPage/Screen/components/DichotomousQuestion/DichotomousQuestion';
import DragnDropList from '../pages/SurveyPage/Screen/components/DragnDropList/DragnDropList';
import MultipleTextField from '../pages/SurveyPage/Screen/components/MultipleTextField/MultipleTextField';
import NumberBox from '../pages/SurveyPage/Screen/components/NumberBox/NumberBox';
import CheckBoxList from '../pages/SurveyPage/Screen/components/RatingList/components/CheckBoxList/CheckBoxList';
import RatingList from '../pages/SurveyPage/Screen/components/RatingList/RatingList';
import SingleCheckBox from '../pages/SurveyPage/Screen/components/SingleCheckBox/SingleCheckBox';
import SingleTextField from '../pages/SurveyPage/Screen/components/SingleTextField/SingleTextField';
import { StyledMainInfoText, StyledTypography } from '../pages/components/common';

export const isQuestionVisible = (question, screens, getValues) => {
  const dependency = question?.SurveyQuestionDepency;
  const dependencyQuestion = screens
    ?.flatMap(screen =>
      screen?.SurveyQuestionList.map(item => {
        if (item?.SurveyQuestionIdentifier === dependency.SurveyQuestionIdentifier) {
          return item;
        }
        return undefined;
      }),
    )
    .filter(item => item !== undefined)[0];

  if (!dependency || !dependency.SurveyQuestionIdentifier) {
    return true;
  } else if (!isQuestionVisible(dependencyQuestion, screens, getValues)) {
    return false;
  }

  const value = screens
    ?.flatMap(screen =>
      screen?.SurveyQuestionList.map(item => {
        if (item?.SurveyQuestionIdentifier === dependency.SurveyQuestionIdentifier) {
          return item?.Value;
        }
        return undefined;
      }),
    )
    .filter(item => item !== undefined)[0];
  const dependentQuestionValue = getValues(dependency.SurveyQuestionIdentifier) || value;

  const valueToCompare = dependency.ValueToCompare;

  switch (dependency.SurveyQuestionDepencyType) {
    case 'dtIsEqual':
      return dependentQuestionValue && dependentQuestionValue === valueToCompare;
    case 'dtIsNotEqual':
      return dependentQuestionValue && dependentQuestionValue !== valueToCompare;
    case 'dtIsLess':
      return dependentQuestionValue && Number(dependentQuestionValue) < Number(valueToCompare);
    case 'dtIsBigger':
      return dependentQuestionValue && Number(dependentQuestionValue) > Number(valueToCompare);
    case 'dtContains':
      return dependentQuestionValue && dependentQuestionValue.includes(valueToCompare);
    case 'dtIsEmpty':
      return dependentQuestionValue === '';
    case 'dtIsNotEmpty':
      return dependentQuestionValue && dependentQuestionValue !== '';
    default:
      return false;
  }
};

export const handleQuestionAnswered = (setQuestionsAnswered, screens, getValues, screen, handleQuestionCount) => {
  setQuestionsAnswered(0);
  for (let i = 0; i <= screens.length - 1; i++) {
    screens[i]?.SurveyQuestionList.map(item => {
      const currentScreenRules =
        isQuestionVisible(item, screens, getValues) &&
        getValues(item?.SurveyQuestionIdentifier) &&
        getValues(item?.SurveyQuestionIdentifier) !== 'false' &&
        getValues(item?.SurveyQuestionIdentifier).length;

      const screenRules =
        currentScreenRules ||
        (isQuestionVisible(item, screens, getValues) &&
          item?.Value &&
          item?.Value !== 'false' &&
          getValues(item?.SurveyQuestionIdentifier) !== 'false' &&
          item?.Value?.length &&
          (i === screen - 1 ? currentScreenRules : true));

      if (screenRules) {
        return handleQuestionCount(1);
      }
    });
  }
};

export const handleQuestionsCount = (setQuestionsCount, screens, getValues) => {
  setQuestionsCount(0);
  for (let i = 0; i <= screens.length - 1; i++) {
    screens[i]?.SurveyQuestionList.map(item => {
      if (!isQuestionVisible(item, screens, getValues)) {
        return null;
      } else if (isQuestionVisible(item, screens, getValues)) {
        return undefined;
      }
    }).forEach(item => {
      if (item === undefined) {
        setQuestionsCount(prev => prev + 1);
      }
    });
  }
};

export const areAllQuestionsHidden = (operatorArg, counter, screens, screen, handleQuestionCount, getValues) => {
  const operator = operatorArg === 'plus' ? counter + 1 : operatorArg === 'minus' ? -(counter + 1) : 0;
  if (
    screens[screen - 1 + operator]?.SurveyQuestionList?.every(item => {
      if (!isQuestionVisible(item, screens, getValues) && item?.Value) {
        handleQuestionCount(-1);
      }
      return !isQuestionVisible(item, screens, getValues);
    })
  ) {
    return areAllQuestionsHidden(operatorArg, counter + 1, screens, screen, handleQuestionCount, getValues);
  }
  return counter;
};

export const renderQuestions = (apiPostErrors, currentScreenQuestions, handleQuestionCount, questionsAnswered, screens, getValues) => {
  const apiPostErrorsMap = apiPostErrors?.reduce((acc, item) => {
    if (!item.PostSuccesfull && item.ErrorMessage) {
      acc[item.$id] = item.ErrorMessage;
    }
    return acc;
  }, {});
  return currentScreenQuestions?.map(item => {
    if (!isQuestionVisible(item, screens, getValues)) {
      return null;
    }
    const apiPostError = apiPostErrorsMap[item.$id] || null;
    if (item.SurveyQuestionType === 'sqtYesNo') {
      return (
        <DichotomousQuestion
          key={item.$id}
          apiPostError={apiPostError}
          item={item}
          handleQuestionCount={handleQuestionCount}
          questionCount={questionsAnswered}
        />
      );
    } else if (item.SurveyQuestionType === 'sqtMultiLineText') {
      return (
        <MultipleTextField
          key={item.$id}
          apiPostError={apiPostError}
          item={item}
          handleQuestionCount={handleQuestionCount}
          questionCount={questionsAnswered}
        />
      );
    } else if (item.SurveyQuestionType === 'sqtRating' && item.DisplayType === 'dtrHorizontalRadioButton') {
      return <CheckBoxList key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtRating') {
      return <RatingList key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtRanking') {
      return <DragnDropList key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtSingleOption') {
      return <CategoricalQuestion key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtMultiOption') {
      return <CategoricalQuestion key={item.$id} apiPostError={apiPostError} item={item} multiple={true} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtNumber') {
      return <NumberBox key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtDateTime') {
      return <DatePickerQuestion key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtSingleCheckbox') {
      return <SingleCheckBox key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    } else if (item.SurveyQuestionType === 'sqtSingleText') {
      return <SingleTextField key={item.$id} apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
    }
    return (
      <StyledMainInfoText key={item.$id}>
        <StyledTypography variant='h2'>{item.Question}</StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
    );
  });
};
