import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledRatingList = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 570px) {
    flex-direction: column;
    gap: 24px;
    align-items: start;
  }
`;

export const StyledStarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  & span.MuiRating-root {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const StyledRatingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 360px;
  @media (max-width: 570px) {
    width: 320px;
  }
`;

export const StyledHeadingWrapper = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    font-family: 'Avenir Next Bold';
    font-size: 12px;
    width: 100%;
    @media (max-width: 570px) {
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
      padding-bottom: 10px;
    }
  }
`;


export const StyledStarsHeadingWrapper = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    font-family: 'Avenir Next Bold';
    font-size: 12px;
    width: 100%;
    @media (max-width: 570px) {
      overflow-x: auto;
      white-space: nowrap;
      max-width: 280px;
      padding-bottom: 10px;
    }
  }
`;

export const StyledStarsMainWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 900px;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-bottom: 10px;
  }
`;
