import React, { useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  StyledCheckBoxWrapper,
  StyledErrorText,
  StyledLabel,
  StyledMainInfoText,
  StyledMainInfoWrapper,
  StyledOptionBlock,
  StyledTypography,
} from '../../../../components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledOptionWrapper } from './StyledDichotomousQuestion';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';

const DichotomousQuestion = ({ item, handleQuestionCount, questionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;
    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleChange = (val, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), val);
    onChange(val);

    if (!item?.Value && val) {
      handleQuestionCount(1);
    } else if (!val) {
      handleQuestionCount(-1);
    }
  };

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>
          {item.Question} {item?.Mandatory && <RequiredStar />}
        </StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <StyledCheckBoxWrapper>
        <StyledOptionWrapper>
          <StyledOptionBlock>
            <Box>
              <Controller
                name={(item?.SurveyQuestionIdentifier).toString()}
                control={control}
                rules={{ required: item?.Mandatory }}
                render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
                  <FormControl component='fieldset' error={!!error}>
                    <RadioGroup value={value || ''} onChange={e => handleChange(e.target.value, onChange)}>
                      <FormControlLabel
                        value='1'
                        control={<Radio icon={<RadioButtonUncheckedIcon />} disabled={item?.ShowReadOnly} checkedIcon={<RadioButtonCheckedIcon />} />}
                        label={<StyledLabel>{item?.TrueLabel}</StyledLabel>}
                      />
                      <FormControlLabel
                        value='0'
                        control={<Radio icon={<RadioButtonUncheckedIcon />} disabled={item?.ShowReadOnly} checkedIcon={<RadioButtonCheckedIcon />} />}
                        label={<StyledLabel>{item?.FalseLabel}</StyledLabel>}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Box>
          </StyledOptionBlock>
        </StyledOptionWrapper>
        <StyledErrorText paddingtop='10px'>
          {errors[(item?.SurveyQuestionIdentifier).toString()] && (
            <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
          )}
          {apiPostError && <div className='error-text'>{apiPostError}</div>}
        </StyledErrorText>
      </StyledCheckBoxWrapper>
    </StyledMainInfoWrapper>
  );
};

export default DichotomousQuestion;
