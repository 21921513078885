import { Box, FormControl, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledErrorText, StyledMainInfoText, StyledMainInfoWrapper, StyledTypography } from '../../../../components/common';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';

const NumberBox = ({ item, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleChange = (val, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), val);
    onChange(val);
    if (!item?.Value && val) {
      handleQuestionCount(1);
    } else if (!val) {
      handleQuestionCount(-1);
    }
  };

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>{item.Question} {item?.Mandatory && <RequiredStar />}</StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <Box sx={{ display: 'flex', justifyContent: 'start' }}>
        <Controller
          key={item.$id}
          name={(item?.SurveyQuestionIdentifier).toString()}
          control={control}
          rules={{
            required: item?.Mandatory,
            validate: {
              number: value => !value || !isNaN(value) || 'Invalid number',
              min: value => !value || Number(value) >= item.Minimum || `Minimum value is ${item.Minimum}`,
              max: value => !value || Number(value) <= item.Maximum || `Maximum value is ${item.Maximum}`,
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <TextField
                {...field}
                value={field.value}
                disabled={item?.ShowReadOnly}
                onChange={e => {
                  handleChange(e.target.value, field.onChange);
                }}
              />
            </FormControl>
          )}
        />
      </Box>
      <StyledErrorText paddingtop='20px'>
        {errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'required' && <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>}
        {errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'number' && <div className='error-text'>{item?.InvalidInputMessage || 'Invalid Number'}</div>}
        {errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'min' && <div className='error-text'>Minimum value is {item.Minimum}</div>}
        {errors[(item?.SurveyQuestionIdentifier).toString()]?.type === 'max' && <div className='error-text'>Maximum value is {item.Maximum}</div>}
        {apiPostError && <div className='error-text'>{apiPostError}</div>}
      </StyledErrorText>
    </StyledMainInfoWrapper>
  );
};

export default NumberBox;
