import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { StyledContainer } from './StyledWrapper';
import { StyledImage } from '../Banner/StyledBanner';
import { Helmet } from 'react-helmet';

const Wrapper = ({
  children,
  isHeader = true,
  headerText,
  imageUrl,
  isFirstPage = false,
  currentScreen,
  screens,
  isLastPage = false,
  imgBannerUrl,
  languages,
  handleLanguage,
  questions,
  questionCount,
  showProgressBar = true
}) => {
  return (
    <>
      <StyledContainer maxWidth='xl' padding='0px'>
        <Helmet>
          <meta name='robots' content='noindex, nofollow' />
        </Helmet>
        {isHeader && <Header text={headerText} imageUrl={imageUrl} languages={languages} handleLanguage={handleLanguage} />}
        {!isHeader && <StyledImage src={imgBannerUrl} alt='Image Logo' />}
      </StyledContainer>

      <main>
        <StyledContainer maxWidth='xl'>{children}</StyledContainer>
      </main>
      <Footer isFirstPage={isFirstPage} questionCount={questionCount} questions={questions} currentScreen={currentScreen} isLastPage={isLastPage} showProgressBar={showProgressBar} />
    </>
  );
};

export default Wrapper;
