import { Box, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const StyledCheckOptionBlock = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 20px;
  flex-direction: column;
  font-size: 14px;
`;

export const StyledCheckBoxList = styled('ul')`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 570px) {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 10px;
    & label span {
      padding: 9px 0 0 0;
    }
    gap: 10px;
  }

  div label {
    margin: 0;
  }
  &::-webkit-scrollbar {
    height: 5px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey !important;
    border-radius: 6px !important;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  flex-direction: column;
`;

export const StyledMainInfoWrapperCheckBoxList = styled(Box)`
  display: flex;

  flex-direction: column;

  max-width: ${props => props.maxwidth || '100%'};
  padding-top: ${props => props.paddingtop || '0px'};
  padding-bottom: ${props => props.paddingbottom || '0px'};
`;

export const StyledMainInfoWrapperCheckBoxListNoDesc = styled(Box)`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 700px;
  justify-content: space-between;
  // max-width: ${props => props.maxwidth || '100%'};
  padding-top: ${props => props.paddingtop || '0px'};
  padding-bottom: ${props => props.paddingbottom || '0px'};
  @media (max-width: 570px) {
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14.5px;
  }
`;

export const StyledCheckBoxesMainWrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 700px;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-bottom: 10px;
  }
`;
