import React from 'react';
import { StyledFooter, StyledFooterWrapper, StyledTypography } from './StyledFooter';

const Footer = ({ questions, isFirstPage, currentScreen, isLastPage, questionCount, showProgressBar }) => {

  return (
    <StyledFooterWrapper>
      <StyledFooter sx={{ backgroundColor: 'secondary.main' }}>
        <StyledTypography variant='h3'>
          {isFirstPage ? `Start!` : isLastPage ? 'Finish!' : showProgressBar && `${questionCount}/${questions} questions`}
        </StyledTypography>
      </StyledFooter>
    </StyledFooterWrapper>
  );
};

export default Footer;
