import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { StyledIcon, StyledSelect, StyledSelectWraper } from './StyledLanguageSelect';
import selectIcon from '../../../assets/icons/iconamoon_arrow-up-2-duotone.svg';

const LanguageSelect = ({ languages, handle }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages?.LanguageList[languages?.DefaultItemIndex - 1]?.Code);

  const handleLanguageChange = event => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
    handle(event.target.value);
  };

  return (
    <StyledSelectWraper>
      <StyledSelect
        value={selectedLanguage}
        IconComponent={props => <StyledIcon {...props} alt='Select Icon' src={selectIcon} />}
        onChange={handleLanguageChange}
      >
        {languages?.LanguageList?.map(language => (
          <MenuItem key={language.Code} value={language.Code} defaultValue='EN'>
            {language.Code}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledSelectWraper>
  );
};

export default LanguageSelect;
