import { createTheme } from '@mui/material';

export const createOwnTheme = htmlStyling => {
  const theme = createTheme({
    palette: {
      primary: {
        main: `#${htmlStyling.Color1}`,
      },
      secondary: {
        main: `#${htmlStyling.Color2}`,
      },
      common: {
        main: `#${htmlStyling.Color3}`,
      },
    },
    typography: {
      h3: {
        color: `#${htmlStyling.TextColor2}`,
      },
      h1: {
        color: `#${htmlStyling.TextColor1}`,
      },
      h2: {
        color: `#${htmlStyling.TextColor1}`,
      },
    },
    overrides: {
      MuiSlider: {
        thumb: {
          color: 'yellow',
        },
        track: {
          color: 'red',
        },
        rail: {
          color: 'black',
        },
      },
    },
  });
  return theme;
};
