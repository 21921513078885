import React from 'react';
import Screen from './Screen/Screen';

const SurveyPage = ({ data, languages, handleLanguage, handleQuestionCount, handleScreen, screen, handleEndData }) => {
  return (
    <Screen
      screens={data.SurveyScreenList}
      questions={data?.TotalAmountQuestions}
      handleQuestionCount={handleQuestionCount}
      surveyData={data}
      handleLanguage={handleLanguage}
      styling={data.SurveyStyling}
      languages={languages}
      handleScreen={handleScreen}
      screen={screen}
      handleEndData={handleEndData}
    />
  );
};

export default SurveyPage;
