import React from 'react';
import Banner from '../components/Banner/Banner';
import Wrapper from '../components/Wrapper/Wrapper';

const HomePage = ({ data, handleClick, screenNumber }) => {
  return (
    <Wrapper isHeader={false} imageUrl={data?.SurveyStartScreen?.ImageURL} isFirstPage={true} imgBannerUrl={data?.SurveyStartScreen?.ImageURL}>
      <Banner styling={data?.SurveyStyling} startScreen={data?.SurveyStartScreen} handleClick={handleClick} screenNumber={screenNumber} />
    </Wrapper>
  );
};

export default HomePage;
