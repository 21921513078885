import React from 'react';
import { StyledButton, StyledButtonsWrapper, StyledMainInfoText, StyledMainWrapper, StyledTypography } from '../common';

const Banner = ({ styling, startScreen, handleClick, screenNumber }) => {
  const count = screenNumber || 1
  return (
    <StyledMainWrapper paddingtop='40px' paddingbottom='100px'>
      <StyledMainInfoText h1fontsize='26px' pfontsize='16px'>
        <StyledTypography variant='h1'>{startScreen?.SurveyTitle}</StyledTypography>
        <StyledTypography variant='h3'>{startScreen?.SurveyDescription}</StyledTypography>
      </StyledMainInfoText>

      <StyledButtonsWrapper paddingtop='1em'>
        <StyledButton width='320px' variant='contained' onClick={() => handleClick('plus', count)}>
          {styling?.ButtonStartSurveyCaption}
        </StyledButton>
      </StyledButtonsWrapper>
    </StyledMainWrapper>
  );
};

export default Banner;
