import { Rating } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledErrorText, StyledMainInfoText, StyledMainInfoWrapper, StyledTypography } from '../../../../components/common';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';
import SliderList from './components/SliderList/SliderList';
import { StyledStarsHeadingWrapper, StyledRatingList, StyledRatingWrapper, StyledStarsMainWrapper, StyledStarWrapper } from './StyledRatingList';

const RatingList = ({ item, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleChange = (val, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), val);
    onChange(val);
    if (!item?.Value && val) {
      handleQuestionCount(1);
    } else if (!val) {
      handleQuestionCount(-1);
    }
  };

  const validationRules = {
    required: item?.Mandatory,
  };

  if (item?.Mandatory) {
    validationRules.validate = value => {
      return Object.keys(value).length > 0 || 'Field is required';
    };
  }

  if (item.DisplayType === 'dtrSlider') return <SliderList apiPostError={apiPostError} item={item} handleQuestionCount={handleQuestionCount} />;
  return (
    <StyledMainInfoWrapper>
      <StyledStarsMainWrapper>
        <StyledMainInfoText>
          <StyledTypography variant='h2'>
            {item.Question} {item?.Mandatory && <RequiredStar />}
          </StyledTypography>
          {item.QuestionExplanation && <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>}
        </StyledMainInfoText>
        <StyledStarWrapper>
          <Controller
            name={(item?.SurveyQuestionIdentifier).toString()}
            key={item?.$id}
            control={control}
            rules={validationRules}
            render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
              return (
                <StyledRatingList>
                  <StyledRatingWrapper>
                    <StyledStarsHeadingWrapper maxWidth='320px'>
                      {item?.HeaderValue.map(item => (
                        <p key={item}>{item}</p>
                      ))}
                    </StyledStarsHeadingWrapper>
                    <Rating
                      name={item?.SurveyQuestionIdentifier}
                      disabled={item?.ShowReadOnly}
                      {...fieldProps}
                      onChange={(_, value) => handleChange(value?.toString(), onChange)}
                      value={fieldProps.value || null}
                      size='large'
                      max={item?.ItemList?.length}
                    />
                  </StyledRatingWrapper>
                </StyledRatingList>
              );
            }}
          />
        </StyledStarWrapper>
      </StyledStarsMainWrapper>

      <StyledErrorText paddingtop='20px'>
        {errors[(item?.SurveyQuestionIdentifier).toString()] && <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>}
        {apiPostError && <div className='error-text'>{apiPostError}</div>}
      </StyledErrorText>
    </StyledMainInfoWrapper>
  );
};

export default RatingList;
