import { Box, Select } from '@mui/material';
import styled from 'styled-components';

export const StyledSelectWraper = styled(Box)`
  position: absolute;
  right: 60px;
  bottom: 7px;
  @media (max-width: 800px) {
    position: unset;
    display: flex;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 88px;
    height: 32px;
    box-shadow: 0px 4px 20px 0px rgba(60, 70, 92, 0.16);
    background-color: #ffffff;
    border-radius: 8px;
    border: 0;
    @media (max-width: 570px) {
      width: 68px;
      font-size: 15px;
    }
  }
`;

export const StyledIcon = styled('img')`
  && {
    top: 4px;
    right: 11px;
    @media (max-width: 570px) {
      right: 8px;
    }
  }
`;
