import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import logo from '../../../assets/images/logmein.png';
import { StyledAppBar, StyledTypography } from './StyledHeader';
import styled from 'styled-components';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

const StyledHeaderInfo = styled(Box)`
  justify-content: center;
  font-size: 40px;
  position: relative;
  & h1 {
    margin: 0;
    font-size: 20px;
  }
  @media (max-width: 800px) {
    gap: 20px;
    align-items: center;
  }
`;

const Header = ({ text, imageUrl, languages, handleLanguage }) => {
  return (
    <StyledAppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          ></Typography>

          <StyledHeaderInfo sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <StyledTypography variant='h1' h1fontsize='36px' sx={{ color: '#FFFFFF' }}>
              {text}
            </StyledTypography>
            {languages && <LanguageSelect languages={languages} handle={handleLanguage} />}
          </StyledHeaderInfo>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};
export default Header;
