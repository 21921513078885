import React from 'react';
import ThanksBanner from '../components/ThanksBanner/ThanksBanner';
import Wrapper from '../components/Wrapper/Wrapper';

const ThanksPage = ({ data,endData }) => {
  return (
    <Wrapper imageUrl={endData?.ImageURL} headerText={endData?.ScreenTitle} isLastPage={true}>
      <ThanksBanner title={endData?.SurveyTitle} description={endData?.SurveyDescription} />
    </Wrapper>
  );
};

export default ThanksPage;
