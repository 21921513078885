import axios from 'axios';
import toast from 'react-hot-toast';
import sha256 from 'crypto-js/sha256';

export const handleRequest = async (
  value,
  SurveyID,
  LanguageCode,
  ColorPaletteID,
  IgnoreSurveyScreenNumbers,
  StartWithIntroScreen,
  IgnorePreviousAnswers,
  SurveyType,
  setStatus,
  setData,
  setScreen,
  setHtmlStyling,
  setToken,
  screenNumber,
  isMobile,
) => {
  const cliendId = await axios.post(
    'https://api.sl2.nl/api/relatiebeheer/LoginService/GetToken',
    { ClientID: process.env.REACT_APP_CLIENT_ID },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const sha256Value = sha256(process.env.REACT_APP_SECURITY_API_KEY + process.env.REACT_APP_SALT + cliendId.data.value).toString();

  const token = await axios.post(
    'https://api.sl2.nl/api/relatiebeheer/LoginService/LoginToken',
    { Token: cliendId.data.value, Hash: sha256Value },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  try {
    const queryParams = {
      SurveyID,
      LanguageCode,
      ColorPaletteID,
      IgnoreSurveyScreenNumbers,
      StartWithIntroScreen,
      IgnorePreviousAnswers,
      SurveyType,
      Mobile: isMobile,
    };

    Object.keys(queryParams).forEach(key => {
      if (key === 'LanguageCode' && value) {
        queryParams[key] = value;
      } else if (queryParams[key] === undefined || queryParams[key] === null) {
        delete queryParams[key];
      }
    });

    axios
      .get(`https://api.sl2.nl/api/relatiebeheer/MySurvey/GetMySurvey`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token.data.value}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setStatus(response.status);
        setData(response.data);
        !value && setScreen(screenNumber || response.data.LastSavedScreenNumber);
        setHtmlStyling(response.data.SurveyStyling.HTMLStyling);
        return response;
      })
      .catch(error => {
        setStatus(error.response.status);
      });

    setToken(token.data.value);
  } catch (error) {
    setStatus(error.response.status);
  }
};

export const handleDataFromSurvey = (data, currentScreenQuestions) => {
  return currentScreenQuestions?.map((item, index) => {
    const handleProperty = (val, property) => {
      return (
        item?.ItemList?.length &&
        item?.ItemList?.map(itemList => {
          return (itemList?.ItemAnswerValue.charAt(0).toUpperCase() + itemList?.ItemAnswerValue.slice(1).toLowerCase() ||
            itemList?.ItemAnswerValue ||
            itemList?.ItemAnswerValue.charAt(0).toUpperCase() + itemList?.ItemAnswerValue.slice(1).toLowerCase()) === val?.toString() ||
            itemList?.ItemAnswerValue === val?.toString()
            ? itemList[property]
            : '';
        }).filter(item => item !== '')
      );
    };

    return !Array.isArray(data[item?.SurveyQuestionIdentifier])
      ? {
          SurveyQuestionIdentifier: item?.SurveyQuestionIdentifier,
          SurveyAnswerItems: [
            {
              ItemIdentifier: item?.ItemList?.length
                ? handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemIdentifier')?.length > 1
                  ? handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemIdentifier')
                  : handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemIdentifier')[0]
                : undefined,
              Answer: data[item?.SurveyQuestionIdentifier] || '',
              ItemNumber: item?.ItemList?.length
                ? handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemNumber')?.length > 1
                  ? handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemNumber')
                  : handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemNumber')[0]
                : undefined,
              ItemLabel: item?.ItemList?.length
                ? handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemLabel')?.length > 1
                  ? handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemLabel')
                  : handleProperty(data[item?.SurveyQuestionIdentifier], 'ItemLabel')[0]
                : undefined,
              OriginIdentifier: item?.ItemList?.length
                ? handleProperty(data[item?.SurveyQuestionIdentifier], 'OriginIdentifier')?.length > 1
                  ? handleProperty(data[item?.SurveyQuestionIdentifier], 'OriginIdentifier')
                  : handleProperty(data[item?.SurveyQuestionIdentifier], 'OriginIdentifier')[0]
                : undefined,
            },
          ],
        }
      : {
          SurveyQuestionIdentifier: item?.SurveyQuestionIdentifier,
          SurveyAnswerItems: data[item?.SurveyQuestionIdentifier]?.map(dataItem => ({
            ItemIdentifier: handleProperty(dataItem?.ItemNumber ? dataItem?.ItemAnswerValue : dataItem, 'ItemIdentifier')[0],
            Answer: dataItem?.ItemNumber ? dataItem?.ItemAnswerValue : dataItem,
            ItemNumber: handleProperty(dataItem?.ItemNumber ? dataItem?.ItemAnswerValue : dataItem, 'ItemNumber')[0],
            ItemLabel: handleProperty(dataItem?.ItemNumber ? dataItem?.ItemAnswerValue : dataItem, 'ItemLabel')[0],
            OriginIdentifier: handleProperty(dataItem?.ItemNumber ? dataItem?.ItemAnswerValue : dataItem, 'OriginIdentifier')[0],
          })),
        };
  });
};

export const handleFormSubmit = async (
  data,
  isPrev,
  areAllQuestionsHidden,
  handleScreen,
  surveyData,
  getValues,
  screen,
  token,
  setApiPostErrors,
  setToken,
  currentScreenQuestions,
  screens,
  handleQuestionCount,
  handleEndData,
) => {
  const emptyQuestions = isPrev
    ? areAllQuestionsHidden('minus', 0, screens, screen, handleQuestionCount, getValues)
    : areAllQuestionsHidden('plus', 0, screens, screen, handleQuestionCount, getValues);

  const emptyScreens = emptyQuestions ? emptyQuestions + 1 : 1;
  const currentScreen = isPrev ? screen - emptyScreens : screen + emptyScreens;
  const sendingData = {
    SessionID: surveyData?.SessionID,
    SurveyID: surveyData?.Participant?.SurveyIdentifier,
    Answers: isPrev ? handleDataFromSurvey(getValues(), currentScreenQuestions) : handleDataFromSurvey(data, currentScreenQuestions),
    CurrentScreen: currentScreen > screens.length ? 0 : currentScreen,
  };
  const config = {
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post('https://api.sl2.nl/api/relatiebeheer/MySurvey/SaveMySurveyAnswers', sendingData, config);
    if (response.status === 200) {
      const hasFailedPosts = response.data.AnswerPOSTResults?.map(item => (item.PostSuccesfull === false ? item : null)).filter(
        item => item !== null,
      );
      setApiPostErrors(hasFailedPosts);
      if ((screen === screens?.length || currentScreen > screens?.length) && !isPrev && response?.data?.APIResult?.Result) {
        const sendingData = {
          SessionID: surveyData?.SessionID,
          SurveyID: surveyData?.Participant?.SurveyIdentifier,
        };
        const summaryResponse = await axios.post('https://api.sl2.nl/api/relatiebeheer/MySurvey/ConfirmMySurveyAnswers', sendingData, config);
        handleEndData(summaryResponse?.data);
        if (!hasFailedPosts.length && response?.data?.APIResult?.Result && summaryResponse?.data?.APIResult?.Result) {
          isPrev ? handleScreen('minus', 1) : handleScreen('plus', 1);
        } else {
          toast.error(response?.data?.APIResult?.ErrorMessage || summaryResponse?.data?.APIResult?.ErrorMessage || 'An error occurred!');
        }
      } else {
        if (!hasFailedPosts.length && response?.data?.APIResult?.Result) {
          isPrev ? handleScreen('minus', 1) : handleScreen('plus', 1);
        } else {
          toast.error(response?.data?.APIResult?.ErrorMessage || 'An error occurred!');
        }
      }
    } else {
      console.log('Request failed with status:', response.status);
    }
  } catch (error) {
    if (error.response.status === 401) {
      try {
        const cliendId = await axios.post(
          'https://api.sl2.nl/api/relatiebeheer/LoginService/GetToken',
          { ClientID: process.env.REACT_APP_CLIENT_ID },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const sha256Value = sha256(process.env.REACT_APP_SECURITY_API_KEY + process.env.REACT_APP_SALT + cliendId.data.value).toString();
        const token = await axios.post(
          'https://api.sl2.nl/api/relatiebeheer/LoginService/LoginToken',
          { Token: cliendId.data.value, Hash: sha256Value },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        setToken(token.data.value);
        const newConfig = {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.data.value}`,
          },
        };
        axios.post('https://api.sl2.nl/api/relatiebeheer/MySurvey/SaveMySurveyAnswers', sendingData, newConfig);
      } catch (loginError) {
        console.log('Login error:', loginError);
      }
    } else {
      toast.error(error?.message);
    }
  }
  if (isPrev ? areAllQuestionsHidden('minus', 0, screens, screen, handleQuestionCount, getValues) : emptyQuestions) {
    isPrev ? handleScreen('minus', emptyQuestions) : handleScreen('plus', emptyQuestions);
  }
};
