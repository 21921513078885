import { Box, Checkbox, Radio } from '@mui/material';
import styled from 'styled-components';

export const StyledImagesList = styled('ul')`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  @media (max-width: 570px) {
    flex-direction: column;
  }
`;

export const StyledMainImageContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.paddingtop || '0px'};
`;

export const StyledMenuImage = styled('img')`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  transition: 1s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    position: ${props => props.position || 'initial'};
    padding: 0;
    top: 8px;
    right: 8px;
  }
`;

export const StyledRadio = styled(Radio)`
  && {
    position: ${props => props.position || 'initial'};
    padding: 0;
    top: 8px;
    right: 8px;
  }
`;

export const StyledMenuImageWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
  p {
    font-family: 'Avenir Next Bold';
  }
`;

export const StyledLabelWrapper = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
