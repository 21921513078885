import React from 'react';
import { StyledCheckbox, StyledRadio } from '../StyledImageMenu';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const RadioCheckbox = ({ multiple, fieldProps, image, value, handleCheckBoxMultiple, handleCheckBox, onChange, item, position }) => {
  return multiple ? (
    <StyledCheckbox
      {...fieldProps}
      value={image.ItemAnswerValue}
      disabled={item?.ShowReadOnly}
      color='common'
      checked={value && value.includes(image.ItemAnswerValue)}
      onChange={() => handleCheckBoxMultiple(value, image.ItemAnswerValue, onChange)}
      position={position}
    />
  ) : (
    <StyledRadio
      {...fieldProps}
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<RadioButtonCheckedIcon />}
      checked={value === image.ItemAnswerValue}
      disabled={item?.ShowReadOnly}
      onChange={() => handleCheckBox(image.ItemAnswerValue, onChange)}
      position={position}
    />
  );
};

export default RadioCheckbox;
