import { AppBar, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledLogo = styled('img')`
  object-fit: cover;
`;
export const StyledAppBar = styled(AppBar)`
  && {
    height: 100px;
    justify-content: center;
    position: relative;
  }
`;

export const StyledTypography = styled(Typography)`
  && {
    font-weight: 550;
    font-size: ${props => props.h1fontsize || '40px'};
    margin: 0;
    font-family: 'Campton', sans-serif;
    @media (max-width: 570px) {
      font-size: 19px;
    }
  }
`;
