import React, { useEffect } from 'react';
import { Checkbox, FormControl } from '@mui/material';
import {
  StyledErrorText,
  StyledMainInfoText,
  StyledMainInfoWrapper,
  StyledSingleCheckBoxWrapper,
  StyledTypography,
} from '../../../../components/common';
import { Controller, useFormContext } from 'react-hook-form';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';

const SingleCheckBox = ({ item, handleQuestionCount, apiPostError }) => {
  const { control, setValue, errors, getValues } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue, item]);

  const handleCheckboxChange = event => {
    const value = event.target.checked;
    setValue((item?.SurveyQuestionIdentifier).toString(), value.toString());
    if (value) {
      handleQuestionCount(1);
    } else if (!value) {
      handleQuestionCount(-1);
    }
  };

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>
          {item.Question} {item?.Mandatory && <RequiredStar />}
        </StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <StyledSingleCheckBoxWrapper>
        <Controller
          name={(item?.SurveyQuestionIdentifier).toString()}
          control={control}
          rules={{ required: item?.Mandatory }}
          defaultValue={''}
          render={({ field }) => (
            <FormControl>
              <Checkbox
                color='common'
                disabled={item?.ShowReadOnly}
                checked={field.value === 'true'}
                onChange={e => {
                  field.onChange(e.target.checked);
                  handleCheckboxChange(e);
                }}
              />
            </FormControl>
          )}
        />

        <p>{item?.CheckboxLabel}</p>
      </StyledSingleCheckBoxWrapper>
      <StyledErrorText paddingtop='10px'>
        {errors[(item?.SurveyQuestionIdentifier).toString()] && <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>}
        {apiPostError && <div className='error-text'>{apiPostError}</div>}
      </StyledErrorText>
    </StyledMainInfoWrapper>
  );
};

export default SingleCheckBox;
