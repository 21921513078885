import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Controller, useFormContext } from 'react-hook-form';
import listIcon from '../../../../../assets/images/list-icon.png';
import { StyledErrorText, StyledMainInfoText, StyledMainInfoWrapper, StyledTypography } from '../../../../components/common';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';
import { StyledDragList, StyledDragNumber, StyledDragOption, StyledDragTextWrapper, StyledImage } from './StyledDragnDropList';

const DragnDropList = ({ item, handleQuestionCount, apiPostError }) => {
  const [characters, setCharacters] = useState(item?.ItemList);
  const [savedDragList, setSavedDragList] = useState({});
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();
    const savedValue =
      surveyQuestionIdentifier &&
      values.hasOwnProperty(surveyQuestionIdentifier) &&
      values[surveyQuestionIdentifier] !== undefined &&
      Array.isArray(values[surveyQuestionIdentifier])
        ? values[surveyQuestionIdentifier]
        : item?.Value?.map(item => ({
            ItemNumber: item?.ItemNumber,
            ItemAnswerValue: item.Answer,
          }));
    if (savedValue?.length) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
      setCharacters(savedValue);
      setSavedDragList(savedValue);
    }
  }, [setValue]);

  const handleOnDragEnd = result => {
    if (!item?.ShowReadOnly) {
      if (!result.destination) return;

      const items = Array.from(characters);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setCharacters(items);
      const updatedDragList = [];
      const valueArray = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        updatedDragList.push({
          ItemNumber: item.ItemNumber,
          ItemAnswerValue: item.ItemAnswerValue,
        });
        valueArray.push(item.ItemAnswerValue);
      }
      setCharacters(updatedDragList);
      setValue((item?.SurveyQuestionIdentifier).toString(), updatedDragList);
      if (!item?.Value && valueArray.length) {
        handleQuestionCount(1);
      } else if (!valueArray.length) {
        handleQuestionCount(-1);
      }
    }
  };
  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>
          {item.Question} {item?.Mandatory && <RequiredStar />}
        </StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='characters'>
          {provided => (
            <StyledDragList className='characters' {...provided.droppableProps} ref={provided.innerRef}>
              {characters?.map(({ ItemNumber, ItemAnswerValue }, index) => {
                return (
                  <Controller
                    key={ItemNumber}
                    name={(item?.SurveyQuestionIdentifier).toString()}
                    control={control}
                    rules={{ required: item?.Mandatory }}
                    defaultValue={savedDragList && savedDragList[index] ? savedDragList[index].ItemAnswerValue : ItemAnswerValue}
                    render={({ field, fieldState: { error } }) => (
                      <Draggable draggableId={ItemNumber.toString()} index={index}>
                        {provided => (
                          <StyledDragOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className='characters-thumb'>
                              <StyledImage src={listIcon} alt='Draggable Image' />
                            </div>
                            <StyledDragTextWrapper sx={{ backgroundColor: !item?.ShowReadOnly ? 'common.main' : 'rgba(0, 0, 0, 0.26)' }} {...field}>
                              <p>{ItemAnswerValue}</p> <StyledDragNumber sx={{ backgroundColor: !item?.ShowReadOnly ? '#b8e3b5' : 'rgba(0, 0, 0, 0.26)' }}>{ItemNumber}</StyledDragNumber>
                            </StyledDragTextWrapper>
                          </StyledDragOption>
                        )}
                      </Draggable>
                    )}
                  />
                );
              })}
              {provided.placeholder}
            </StyledDragList>
          )}
        </Droppable>
        <StyledErrorText paddingtop='20px'>
          {errors[(item?.SurveyQuestionIdentifier).toString()] && (
            <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
          )}
             {apiPostError && <div className='error-text'>{apiPostError}</div>}
        </StyledErrorText>
      </DragDropContext>
    </StyledMainInfoWrapper>
  );
};

export default DragnDropList;
