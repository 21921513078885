import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StyledErrorText, StyledMainInfoText, StyledMainInfoWrapper, StyledTypography } from '../../../../components/common';
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledDateWrapper } from './StyledDatePicker';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import RequiredStar from '../../../../components/RequiredStar/RequiredStar';

const DatePickerQuestion = ({ item, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleInputChange = (val, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), dayjs(val));
    onChange(dayjs(val));
    if (!item?.Value && val) {
      handleQuestionCount(1);
    } else if (!val) {
      handleQuestionCount(-1);
    }
  };

  return (
    <StyledMainInfoWrapper>
      <StyledMainInfoText>
        <StyledTypography variant='h2'>{item.Question} {item?.Mandatory && <RequiredStar />}</StyledTypography>
        <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>
      </StyledMainInfoText>
      <StyledDateWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            key={item.$id}
            name={(item?.SurveyQuestionIdentifier).toString()}
            control={control}
            rules={{ required: item?.Mandatory }}
            render={({ field: { ref, onBlur, name, onChange, value }, fieldState: { error } }) => (
              <FormControl>
                {item?.InputType === 'dtoDateOnly' ? (
                  <DatePicker
                    inputRef={ref}
                    value={dayjs(value) || null}
                    onChange={newValue => handleInputChange(newValue, onChange)}
                    sx={{ width: '270px' }}
                    disabled={item?.ShowReadOnly}
                  />
                ) : item?.InputType === 'dtoTimeOnly' ? (
                  <TimePicker
                    inputRef={ref}
                    value={dayjs(value) || null}
                    onChange={newValue => handleInputChange(newValue, onChange)}
                    sx={{ width: '270px' }}
                    disabled={item?.ShowReadOnly}
                  />
                ) : (
                  <DateTimePicker
                    inputRef={ref}
                    value={dayjs(value) || null}
                    onChange={newValue => handleInputChange(newValue, onChange)}
                    sx={{ width: '270px' }}
                    disabled={item?.ShowReadOnly}
                  />
                )}
              </FormControl>
            )}
          />
        </LocalizationProvider>
      </StyledDateWrapper>

      <StyledErrorText paddingtop='20px'>
        {errors[(item?.SurveyQuestionIdentifier).toString()] && <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>}
        {apiPostError && <div className='error-text'>{apiPostError}</div>}
      </StyledErrorText>
    </StyledMainInfoWrapper>
  );
};

export default DatePickerQuestion;
