import React, { useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FormControl, Radio, RadioGroup } from '@mui/material';
import {
  StyledCheckBoxesMainWrapper,
  StyledCheckBoxList,
  StyledCheckOptionBlock,
  StyledFormControlLabel,
  StyledMainInfoWrapperCheckBoxList,
  StyledMainInfoWrapperCheckBoxListNoDesc,
} from './StyledCheckBoxList';
import { StyledErrorText, StyledLabel, StyledMainInfoText, StyledMainInfoWrapper, StyledTypography } from '../../../../../../components/common';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledHeadingWrapper } from '../../StyledRatingList';
import RequiredStar from '../../../../../../components/RequiredStar/RequiredStar';

const CheckBoxList = ({ item, handleQuestionCount, apiPostError }) => {
  const { control, setValue, getValues, errors } = useFormContext();

  useEffect(() => {
    const surveyQuestionIdentifier = item?.SurveyQuestionIdentifier;
    const values = getValues();

    const savedValue =
      surveyQuestionIdentifier && values.hasOwnProperty(surveyQuestionIdentifier) && values[surveyQuestionIdentifier] !== undefined
        ? values[surveyQuestionIdentifier]
        : item?.Value;

    if (savedValue) {
      setValue((item?.SurveyQuestionIdentifier).toString(), savedValue);
    }
  }, [setValue]);

  const handleChange = (val, onChange) => {
    setValue((item?.SurveyQuestionIdentifier).toString(), val);
    onChange(val);
    if (!item?.Value && val) {
      handleQuestionCount(1);
    } else if (!val) {
      handleQuestionCount(-1);
    }
  };

  const validationRules = {
    required: item?.Mandatory,
  };

  return (
    <>
      {item?.HeaderValue?.length && item?.HeaderValue[0] !== ' ' ? (
        <StyledMainInfoWrapper>
          <StyledCheckBoxesMainWrapper>
            <StyledMainInfoText>
              <StyledTypography variant='h2'>
                {item.Question} {item?.Mandatory && <RequiredStar />}
              </StyledTypography>
              {item.QuestionExplanation && <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>}
            </StyledMainInfoText>
            <StyledCheckBoxList>
              <Controller
                name={(item?.SurveyQuestionIdentifier).toString()}
                control={control}
                rules={validationRules}
                render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
                  <FormControl component='fieldset' error={!!error}>
                    {item?.HeaderValue?.length && item?.HeaderValue[0] !== ' ' ? (
                      <StyledHeadingWrapper>
                        {item?.HeaderValue.map(item => (
                          <p key={item}>{item}</p>
                        ))}
                      </StyledHeadingWrapper>
                    ) : null}
                    <RadioGroup value={value || ''} onChange={e => handleChange(e.target.value, onChange)}>
                      <StyledCheckBoxList>
                        {item.ItemList.map(rating => (
                          <StyledCheckOptionBlock key={rating.$id}>
                            <StyledFormControlLabel
                              value={rating.ItemAnswerValue.toString()}
                              control={
                                <Radio icon={<RadioButtonUncheckedIcon />} disabled={item?.ShowReadOnly} checkedIcon={<RadioButtonCheckedIcon />} />
                              }
                              label={item?.ShowNumbers && <StyledLabel>{rating.ItemLabel}</StyledLabel>}
                            />
                          </StyledCheckOptionBlock>
                        ))}
                      </StyledCheckBoxList>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </StyledCheckBoxList>
          </StyledCheckBoxesMainWrapper>

          <StyledErrorText>
            {errors[(item?.SurveyQuestionIdentifier).toString()] && (
              <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
            )}
            {apiPostError && <div className='error-text'>{apiPostError}</div>}
          </StyledErrorText>
        </StyledMainInfoWrapper>
      ) : item.QuestionExplanation ? (
        <StyledMainInfoWrapperCheckBoxList>
          <StyledMainInfoText>
            <StyledTypography variant='h2'>
              {item.Question} {item?.Mandatory && <RequiredStar />}
            </StyledTypography>
            {item.QuestionExplanation && <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>}
          </StyledMainInfoText>
          <StyledCheckBoxList>
            <Controller
              name={(item?.SurveyQuestionIdentifier).toString()}
              control={control}
              rules={validationRules}
              render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
                <FormControl component='fieldset' error={!!error}>
                  {item?.HeaderValue?.length && item?.HeaderValue[0] !== ' ' ? (
                    <StyledHeadingWrapper>
                      {item?.HeaderValue.map(item => (
                        <p key={item}>{item}</p>
                      ))}
                    </StyledHeadingWrapper>
                  ) : null}
                  <RadioGroup value={value || ''} onChange={e => handleChange(e.target.value, onChange)}>
                    <StyledCheckBoxList>
                      {item.ItemList.map(rating => (
                        <StyledCheckOptionBlock key={rating.$id}>
                          <StyledFormControlLabel
                            value={rating.ItemAnswerValue.toString()}
                            control={
                              <Radio icon={<RadioButtonUncheckedIcon />} disabled={item?.ShowReadOnly} checkedIcon={<RadioButtonCheckedIcon />} />
                            }
                            label={item?.ShowNumbers && <StyledLabel>{rating.ItemLabel}</StyledLabel>}
                          />
                        </StyledCheckOptionBlock>
                      ))}
                    </StyledCheckBoxList>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </StyledCheckBoxList>
            <StyledErrorText>
              {errors[(item?.SurveyQuestionIdentifier).toString()] && (
                <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
              )}
              {apiPostError && <div className='error-text'>{apiPostError}</div>}
            </StyledErrorText>
    
        </StyledMainInfoWrapperCheckBoxList>
      ) : (
        <StyledMainInfoWrapperCheckBoxListNoDesc>
          <StyledMainInfoText>
            <StyledTypography variant='h2'>
              {item.Question} {item?.Mandatory && <RequiredStar />}
            </StyledTypography>
            {item.QuestionExplanation && <StyledTypography variant='h3'>{item.QuestionExplanation}</StyledTypography>}
          </StyledMainInfoText>
          <StyledCheckBoxList>
            <Controller
              name={(item?.SurveyQuestionIdentifier).toString()}
              control={control}
              rules={validationRules}
              render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
                <FormControl component='fieldset' error={!!error}>
                  {item?.HeaderValue?.length && item?.HeaderValue[0] !== ' ' ? (
                    <StyledHeadingWrapper>
                      {item?.HeaderValue.map(item => (
                        <p key={item}>{item}</p>
                      ))}
                    </StyledHeadingWrapper>
                  ) : null}
                  <RadioGroup value={value || ''} onChange={e => handleChange(e.target.value, onChange)}>
                    <StyledCheckBoxList>
                      {item.ItemList.map(rating => (
                        <StyledCheckOptionBlock key={rating.$id}>
                          <StyledFormControlLabel
                            value={rating.ItemAnswerValue.toString()}
                            control={
                              <Radio icon={<RadioButtonUncheckedIcon />} disabled={item?.ShowReadOnly} checkedIcon={<RadioButtonCheckedIcon />} />
                            }
                            label={item?.ShowNumbers && <StyledLabel>{rating.ItemLabel}</StyledLabel>}
                          />
                        </StyledCheckOptionBlock>
                      ))}
                    </StyledCheckBoxList>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </StyledCheckBoxList>
          {errors[(item?.SurveyQuestionIdentifier).toString()] && (
            <StyledErrorText>
              {errors[(item?.SurveyQuestionIdentifier).toString()] && (
                <div className='error-text'>{item?.MissingInputMessage || 'Field is required'}</div>
              )}
                 {apiPostError && <div className='error-text'>{apiPostError}</div>}
            </StyledErrorText>
          )}
        </StyledMainInfoWrapperCheckBoxListNoDesc>
      )}
    </>
  );
};

export default CheckBoxList;
